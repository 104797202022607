var _this4 = this;
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "BasicInfo",
  data: function data() {
    return {
      username: '',
      // 用户名
      // 头像字段
      avatar: '',
      // 性别字段
      radio: '',
      roleName: '',
      phone: '',
      email: '',
      creatAt: '',
      loginAt: '',
      // 修改名字弹框状态
      modifyDialogVisible: false,
      // 修改名字模型数据
      modifyData: {
        username: ''
      },
      file: ''
    };
  },
  mounted: function mounted() {
    this.initInfo();
  },
  methods: {
    // 初始化用户基本信息
    initInfo: function initInfo() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: "getInfo",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            // this.$toast.success(res.message)
            _this2.username = res.data.username;
            _this2.avatar = res.data.avatar;
            _this2.radio = res.data.gender;
            _this2.roleName = res.data.role[0].roleName;
            _this2.phone = res.data.mobile;
            _this2.email = res.data.email;
            _this2.creatAt = res.data.createdAt;
            _this2.loginAt = res.data.info.loginTime;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // 修改图标
    editAvatarFun: function editAvatarFun() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: "editAvatar",
        data: {
          avatar: this.avatar
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            // this.$toast.success(res.message)
            _this3.$store.commit('setUserItem', {
              type: 'avatar',
              data: _this3.avatar
            });
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        _this.avatar = this.result;
      };
      uploadFile({
        folderName: "logo",
        complete: function complete(res, url) {
          _this.avatar = url;
          _this.editAvatarFun();
        }
      }, _this.file);
    },
    //上传图片转base64
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      _this4.imageUrl = URL.createObjectURL(uploadFile.raw);
    },
    // 修改用户名
    modifyFun: function modifyFun() {
      this.modifyDialogVisible = true;
    },
    confirmFun: function confirmFun() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: "editName",
        data: {
          username: this.modifyData.username
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.$toast.success(res.message);
            _this5.$store.commit('setUserName', _this5.modifyData.username);
            _this5.initInfo();
            _this5.modifyDialogVisible = false;
            _this5.modifyData.username = '';
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    modifyCancel: function modifyCancel() {
      this.modifyDialogVisible = false;
      this.modifyData.username = '';
    },
    // 修改性别
    radioChange: function radioChange(val) {
      var _this6 = this;
      var that = this;
      // that.btnstatus=(val==='1')?true:false;

      this.service.axiosUtils({
        requestName: "editGender",
        data: {
          gender: this.radio
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            // this.$toast.success(res.message)
            _this6.$store.commit('setUserItem', {
              type: 'gender',
              data: _this6.radio
            });
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    }
  }
};